import { useEffect, useState } from 'react';
import debounce from 'lodash.debounce';

const isClient = () => typeof window === 'object';

const isObject = obj => obj && typeof obj === 'object';

const fastCompare = (a, b) => {
  if (a === b) return true;
  if ((isObject(a) && isObject(b)) || (Array.isArray(a) && Array.isArray(b))) {
    let i;
    for (i in a) {
      if (b[i] !== a[i]) return false;
    }
    for (i in b) {
      if (a[i] !== b[i]) return false;
    }
    return true;
  }
  return false;
};

// Do we need this?
export const midBreakPoint = 1280;
const getSize = () => {
  if (!isClient()) return {};
  const { innerWidth } = window;
  const isMobile = innerWidth < 768;
  const isTablet = innerWidth >= 768 && innerWidth < 1024;
  const isDesktop = innerWidth >= 1024;
  return {
    isMobile,
    isTablet,
    isDesktop,
  };
};
const useWindowWidth = (delay = 100) => {
  const [windowSize, setWindowSize] = useState(getSize);
  useEffect(() => {
    if (!isClient()) return false;
    const handleResize = () => {
      const nextWindowSize = getSize();
      if (!fastCompare(nextWindowSize, windowSize)) {
        setWindowSize(nextWindowSize);
      }
    };
    const debouncedHandleResize = debounce(handleResize, delay);
    window.addEventListener('resize', debouncedHandleResize);
    return () => {
      debouncedHandleResize.cancel();
      window.removeEventListener('resize', debouncedHandleResize);
    };
  }, [windowSize]);
  return windowSize;
};

export default useWindowWidth;
