import React from 'react';
import styled from '@emotion/styled';
import { graphql, useStaticQuery } from 'gatsby';
import Image from 'gatsby-image';
import BackgroundImage from 'gatsby-background-image';

import useWindowWidth from '../../hooks/use-window-width';

const StyledSmallHero = styled(BackgroundImage)`
  align-items: center;
  color: #ffffff;
  display: flex;
  justify-content: center;
  min-height: 200px;
  text-align: center;
  @media only screen and (max-width: 1140px) {
    max-height: 250px;
  }
  @media only screen and (max-width: 768px) {
    max-height: 250px;
  }
`;

const BigHeroWrapper = styled.div`
  max-height: 425px;
  overflow: hidden;
  @media only screen and (max-width: 992px) {
    max-height: 100%;
  }
`;

const Hero = ({ isBig = true, children }) => {
  const { bigHero, smallHero } = useStaticQuery(graphql`
    query {
      bigHero: file(relativePath: { eq: "banner-ghcp-1080.jpg" }) {
        sharp: childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      smallHero: file(
        relativePath: { eq: "banner-ghcp-2560-1346-no-text.jpg" }
      ) {
        sharp: childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  const { isDesktop } = useWindowWidth(200);

  return isBig ? (
    <BigHeroWrapper>
      <Image
        fluid={bigHero.sharp.fluid}
        imgStyle={{ marginTop: isDesktop ? '-7.5vw' : 0 }}
      />
    </BigHeroWrapper>
  ) : (
    <StyledSmallHero
      fluid={smallHero.sharp.fluid}
    >
      {children}
    </StyledSmallHero>
  );
};

export default Hero;
